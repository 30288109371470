const AccordionData = [
    {
        title: "Common PayPal and credit card issues",
        content: "Lorem ipsum is simply dummy text of the printing type setting and industry. Lorem ipsum has been the industry's standard dummy."
    },
    {
        title: "Lets collaborate and make an impact business",
        content: "Lorem ipsum is simply dummy text of the printing type setting and industry. Lorem ipsum has been the industry's standard dummy."
    },
    {
        title: "A satisfied customer is the best business strats",
        content: "Lorem ipsum is simply dummy text of the printing type setting and industry. Lorem ipsum has been the industry's standard dummy."
    }
]

const AccordionData02 = [
    {
        title: "What do you mean by item and end product?",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever when an unknown printer took a galley. Lorem ipsum is simply."
    },
    {
        title: "What are some examples of permitted end products?",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever when an unknown printer took a galley. Lorem ipsum is simply."
    },
    {
        title: "Am i allowed to modify the item that i purchased?",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever when an unknown printer took a galley. Lorem ipsum is simply."
    },
]

const AccordionData05 = [
    {
        time: "06:00 - 07:00",
        title: 'Yoga and Pilates',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: 'By Edward watson'
    },
    {
        time: "08:00 - 09:00",
        title: 'Energy Blast',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: 'By Bryan jonhson'
    },
    {
        time: "10:00 - 11:00",
        title: 'Cardio Workout',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: 'By Jeremy dupont',

    },
    {
        time: "11:00 - 12:00",
        title: 'Athletics Classes',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: ' By Edward watson'
    },
    {
        time: "13:00 - 15:00",
        title: 'Boxercise',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: ' By Bryan jonhson',

    }
]

export {AccordionData ,AccordionData02,AccordionData05};